import React from 'react'
import 'components/burger/index.css'

const BurgerMenu = ({ open, setOpen, ...props }) => {
  const isExpanded = open ? true : false

  return (
    <button
      className="burger-menu"
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <span className={open ? 'isOpen' : ''} />
      <span className={open ? 'isOpen' : ''} />
      <span className={open ? 'isOpen' : ''} />
    </button>
  )
}

export default BurgerMenu
