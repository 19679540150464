import 'pages/blog/index.css'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'api'
import Pagination from 'components/pagination'
import { Button } from 'primereact/button'
import { parseDate } from 'utils/date'

const ARTICLES_PER_PAGE = 5

function Blog() {
  const [articles, setArticles] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  useEffect(() => {
    fetchArticles()
  }, [])

  const fetchArticles = async () => {
    setLoading(true)
    const res = await api.get('/articles')

    if (res.status === 200) {
      setLoading(false)
      setArticles(res.data)
    } else {
      setLoading(false)
      setArticles([])
    }
  }

  const onArticleDelete = async (id) => {
    const res = await api.delete(`/article/${id}`)

    if (res.data.deleted) {
      fetchArticles()
    }
  }

  // Get current articles
  const indexOfLastPost = currentPage * ARTICLES_PER_PAGE
  const indexOfFirstPost = indexOfLastPost - ARTICLES_PER_PAGE
  const currentArticles = articles
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(indexOfFirstPost, indexOfLastPost)

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  if (loading)
    return (
      <section id="blog">
        <div className="d-flex justify-content-center pb-3">
          <div
            className="spinner-border"
            role="status"
            style={{ width: '3rem', height: '3rem' }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </section>
    )

  if (articles.length === 0)
    return (
      <section id="blog">
        <div className="d-flex justify-content-center">
          <h5 className="pb-2">Δε βρέθηκαν άρθρα...</h5>
        </div>
      </section>
    )

  return (
    <section id="blog" className="section">
      {currentArticles.map((article) => (
        <div className="jumbotron" key={article._id}>
          <h1 className="display-4">{article.title}</h1>
          <p className="lead">Δημιουργημένο: {parseDate(article.createdAt)}</p>
          {article.content.length > 150 && (
            <div className="d-flex align-items-end mt-3">
              <Button
                label="Διαβάστε περισσότερα"
                className="p-button-help p-button-rounded p-button-sm"
                onClick={() => history.push(`/article/${article._id}`)}
              />
            </div>
          )}
          {localStorage.getItem('token') && (
            <div className="d-flex mt-3">
              <Button
                className="p-button-danger p-button-sm"
                label="Διαγραφή άρθρου"
                icon="pi pi-trash"
                iconPos="right"
                onClick={() => onArticleDelete(article._id)}
              />
            </div>
          )}
          <hr className="my-4" />
          <p
            className={article.content.length >= 150 ? 'content-overflow' : ''}
          >
            {article.content.length > 150
              ? article.content.slice(0, 150)
              : article.content}
          </p>
        </div>
      ))}
      <Pagination
        currentPage={currentPage}
        articlesPerPage={ARTICLES_PER_PAGE}
        totalArticles={articles.length}
        paginate={paginate}
      />
    </section>
  )
}

export default Blog
