import 'components/header/index.css'
import React, { useState, useEffect } from 'react'
import Scrollspy from 'react-scrollspy'
import { scroller } from 'react-scroll'
import BurgerMenu from 'components/burger'
import ResponsiveMenu from 'components/menu'

function Header() {
  const [routeItems] = useState([
    { label: 'Αρχική', icon: 'pi pi-fw pi-home', link: '/#/home' },
    {
      label: 'Βιογραφικό',
      icon: 'pi pi-id-card',
      link: '/#/bio'
    },
    {
      label: 'Υπηρεσίες',
      icon: 'pi pi-users',
      link: '/#/services'
    },
    {
      label: 'Επικοινωνία',
      icon: 'pi pi-envelope',
      link: '/#/contact'
    },
    {
      label: 'Αρθογραφία',
      icon: 'pi pi-comments',
      link: '/blog'
    }
  ])
  const [showLogo, setShowLogo] = useState(true)
  const [activeWidth, setActiveWidth] = useState(window.innerWidth)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const scrollEvent = () => {
      const currentScrollPos = window.pageYOffset
      const maxScroll = document.body.scrollHeight - window.innerHeight + 30

      if (currentScrollPos > 0 && currentScrollPos < maxScroll) {
        setShowLogo(false)
      } else {
        setShowLogo(true)
      }
    }
    scrollEvent()
    document.addEventListener('scroll', scrollEvent)

    return () => document.removeEventListener('scroll', scrollEvent)
  }, [])

  useEffect(() => {
    const resizeEvent = () => setActiveWidth(window.innerWidth)
    resizeEvent()
    window.addEventListener('resize', resizeEvent)
    return () => window.removeEventListener('resize', resizeEvent)
  }, [])

  const renderMenu = () => (
    <Scrollspy
      items={['home', 'bio', 'services', 'contact', 'blog']}
      currentClassName="active-item"
      offset={activeWidth > 767 ? -82 : -88}
    >
      {routeItems.map((route) => (
        <li
          key={route.label}
          onClick={() => {
            if (route.link === '/blog') return

            scroller.scrollTo(route.link.replace('/#/', ''), {
              duration: 1500,
              delay: 100,
              smooth: true,
              offset: activeWidth > 767 ? -60 : -82
            })
          }}
        >
          <a
            href={route.link}
            onClick={() => {
              if (activeWidth > 767) return
              setOpen(!open)
            }}
          >
            <span className="mr-1">{route.label}</span>
            <i className={route.icon} />
          </a>
        </li>
      ))}
    </Scrollspy>
  )

  return (
    <header className={`menubar ${showLogo ? 'show-logo' : 'hide-logo'}`}>
      <img
        className="img-fluid"
        alt="Logo"
        src={`${process.env.PUBLIC_URL}/images/logo.jpg`}
      />
      {activeWidth <= 767 && (
        <>
          <BurgerMenu open={open} setOpen={(val) => setOpen(val)} />
          <ResponsiveMenu open={open}>{renderMenu()}</ResponsiveMenu>
        </>
      )}
      {renderMenu()}
    </header>
  )
}

export default Header
