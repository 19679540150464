function parseDate(date) {
  const fullDate = new Date(date)
  const day = fullDate.getDate()
  const month = fullDate.getMonth()
  const year = fullDate.getFullYear()

  return `${day}/${month + 1}/${year}`
}

export { parseDate }
