import React from 'react'
import 'components/menu/index.css'

const ResponsiveMenu = ({ open, children, ...props }) => {
  const isHidden = open ? true : false

  return (
    <nav
      className={`menu ${open ? 'isOpen' : ''}`}
      aria-hidden={!isHidden}
      {...props}
    >
      {children}
    </nav>
  )
}

export default ResponsiveMenu
