import 'components/scrollTop/index.css'
import React, { useState, useEffect } from 'react'
import { animateScroll } from 'react-scroll'

function ScrollTop() {
  const [showScroll, setShowScroll] = useState(false)

  useEffect(() => {
    const scrollEvent = () => {
      if (window.pageYOffset > 350) {
        setShowScroll(true)
      } else {
        setShowScroll(false)
      }
    }
    scrollEvent()
    document.addEventListener('scroll', scrollEvent)

    return () => document.removeEventListener('scroll', scrollEvent)
  }, [])

  if (!showScroll) return null

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="scrollTop" onClick={() => animateScroll.scrollToTop()}>
      <i className="pi pi-arrow-up"></i>
    </a>
  )
}

export default ScrollTop
