import React, { useState } from 'react'
import CV from 'react-cv'
import { Button } from 'primereact/button'

function Bio() {
  const [personalData] = useState({
    name: 'Σταύρος Τσιαγκλής',
    title: 'Ειδικός Παθολόγος - Διαβητολόγος',
    image: `${process.env.PUBLIC_URL}/images/1.jpg`,
    contacts: []
  })
  const [sections] = useState([
    {
      type: 'common-list',
      title: 'Εκπαίδευση',
      icon: 'graduation',
      items: [
        {
          title: 'Εθνικό Καποδιστριακό Πανεπιστήμιο Αθηνών',
          authority: 'Ιατρική Σχολή',
          rightSide: '1995 - 2002'
        },
        {
          title: 'Ιατρός Υπηρεσίας Υπαίθρου',
          authority: 'Γενικό Νοσοκομείο Λειβαδιάς',
          rightSide: '01/10/2002 – 02/01/2004'
        },
        {
          title: 'Ειδίκευση στην Εσωτερική Παθολογία (Α’ Παθολογικό Τμήμα)',
          authority: 'Γενικό Νοσοκομείο Πειραιά Τζάνειο',
          rightSide: '11/04/2006 – 10/10/2010',
          description: 'Τίτλος Ειδικότητας: Ειδικός Παθολόγος'
        },
        {
          title: 'Διαβητολογικό Κέντρο Γενικό Νοσοκομείο Νίκαιας',
          authority: 'Τίτλος Εξιδίκευσης: Διαβητολόγος',
          rightSide: '12/2014 - 06/2020'
        }
      ]
    },
    {
      type: 'experiences-list',
      title: 'Επαγγελματική Πείρα',
      icon: 'archive',
      items: [
        {
          title: 'Ιδιωτικό Παθολογικό – Διαβητολογικό Ιατρείο',
          datesBetween: '01/2012 - Σήμερα'
        },
        {
          title: 'Διευθυντής Παθολογικού Τμήματος',
          company: 'Γενική Κλινική Κανακίδη',
          datesBetween: '07/2012 - Σήμερα'
        },
        {
          title: 'Επιστημονικός Συνεργάτης Διαβητολογικού Κέντρου',
          company: 'Γενικό Νοσοκομείο Νίκαιας',
          datesBetween: '12/2014 - Σήμερα'
        },
        {
          title: 'Επιμελητής Ειδικός Παθολόγος',
          company: 'Νοσοκομείο Metropolitan',
          datesBetween: '02/2011 - 03/2017'
        },
        {
          title: 'Ιατρός Παιδικών Κατασκηνώσεων «Αναγέννηση» Κάλαμο Αττικής',
          datesBetween: '16/06/2005 – 23/08/2005'
        },
        {
          title: 'Ιδιωτικό Κέντρο Παροχής Ιατρικών Υπηρεσιών Κατ’ οίκων',
          datesBetween: '02/02/2005 – 06/06/2005'
        }
      ]
    },
    {
      type: 'common-list',
      title: 'Γλώσσες',
      icon: 'language',
      items: [
        {
          authority: 'Ελληνικά',
          authorityMeta: 'Μητρική'
        },
        {
          authority: 'Αγγλικά',
          authorityMeta: 'Άριστη γνώση'
        }
      ]
    }
  ])

  return (
    <>
      <CV personalData={personalData} sections={sections} branding />
      <div className="d-flex justify-content-center mt-3">
        <a
          href={`${process.env.PUBLIC_URL}/files/CV-Stavros-Tsiaglis.doc`}
          rel="noopener noreferrer"
          download
        >
          <Button
            className="p-button-info"
            label="Βιογραφικό"
            icon="pi pi-download"
            iconPos="right"
          />
        </a>
      </div>
    </>
  )
}

export default Bio
