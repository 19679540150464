import axios from 'axios'

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? 'https://api.stsiaglis.gr'
      : 'http://localhost:5000',
  timeout: 1000,
  headers: { Authorization: localStorage.getItem('token') }
})

export default instance
