import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="error-template">
            <h1>Oops!</h1>
            <h2>404 Not Found</h2>
            <div className="error-details">Η σελίδα δεν βρέθηκε</div>
            <div className="error-actions">
              <Link to={'/'} className="btn btn-danger btn-lg">
                <i className="pi pi-fw pi-home mr-2"></i>
                Μετάβαση στην αρχική
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
