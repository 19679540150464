import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import api from 'api'
import { parseDate } from 'utils/date'

function Article() {
  const [article, setArticle] = useState(null)

  const { id } = useParams()

  useEffect(() => {
    const fetchArticle = async () => {
      const res = await api.get(`/article/${id}`)

      if (res.status === 200) {
        return setArticle(res.data)
      } else {
        return setArticle(null)
      }
    }
    fetchArticle()
  }, [id])

  if (!id || !article)
    return (
      <div className="d-flex justify-content-center">
        <h5 className="pb-2">Το άρθρο δεν βρέθηκε...</h5>
      </div>
    )

  return (
    <section id="article">
      <div className="jumbotron" style={{ backgroundColor: 'white' }}>
        <h1 className="display-4">{article.title}</h1>
        <p className="lead">Δημιουργημένο: {parseDate(article.createdAt)}</p>
        <hr className="my-4" />
        <p>{article.content}</p>
      </div>
    </section>
  )
}

export default Article
