import React from 'react'

function Map() {
  return (
    <>
      <iframe
        className="map"
        style={{ width: '100%' }}
        title="Ιατρείο Τσιαγκλής Σταύρος"
        width="650"
        height="550"
        id="gmap_canvas"
        src="https://maps.google.com/maps?width=650&amp;height=550&amp;hl=en&amp;q=%CE%96%CE%B1%CE%BD%CE%BD%CE%AE%2078%20Piraeus+(%CE%99%CE%B1%CF%84%CF%81%CE%B5%CE%AF%CE%BF%20%CE%A4%CF%83%CE%B9%CE%B1%CE%B3%CE%BA%CE%BB%CE%AE%CF%82%20%CE%A3%CF%84%CE%B1%CF%8D%CF%81%CE%BF%CF%82)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        frameBorder="0"
        scrolling="no"
      ></iframe>
      <script
        type="text/javascript"
        src="https://maps-generator.com/google-maps-authorization/script.js?id=e924e80c06056c8e9bde60426bdafabab3e51a58"
      ></script>
    </>
  )
}

export default Map
