import 'bootstrap/dist/css/bootstrap.min.css'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom'
import App from 'pages/home'
import NotFound from 'pages/NotFound'
import Blog from 'pages/blog'
import Header from 'components/header'
import Admin from 'pages/admin'
import Article from 'pages/article'
import ScrollTop from 'components/scrollTop'
import CookieConsent from 'react-cookie-consent'

ReactDOM.render(
  <BrowserRouter>
    <>
      <div
        className="container"
        style={{ boxShadow: '0 9px 25px 0 rgba(132, 128, 177, 0.28)' }}
      >
        <Header />
        <div className="row mt-3">
          <div className="col">
            <Switch>
              <Route exact path="/">
                <HashRouter>
                  <Route path="/" component={App} />
                </HashRouter>
              </Route>
              <Route path="/blog" component={Blog} />
              <Route path="/article/:id" component={Article} />
              <Route path="/admin" component={Admin} />
              <Route path="*" component={NotFound} />
            </Switch>
          </div>
        </div>
      </div>
      <ScrollTop />
      <CookieConsent
        location="bottom"
        buttonText="Αποδοχή Cookies"
        cookieName="myAwesomeCookieName2"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}
      >
        Η ιστοσελίδα χρησιμοποιεί cookies και άλλες τεχνολογίες καταγραφής για
        να εξασφαλίσει την σωστή λειτουργία της.
      </CookieConsent>
    </>
  </BrowserRouter>,
  document.getElementById('root')
)
