import React, { useState, useEffect } from 'react'
import api from 'api'

function Admin() {
  const [state, setState] = useState({ user: null, isAuthenticated: false })
  const [form, setForm] = useState({ email: '', password: '' })
  const [article, setArticle] = useState({ title: '', content: '' })

  useEffect(() => {
    const fetchUser = async () => {
      const res = await api.get('/users/me')

      if (res.status === 201) {
        return setState({ user: res.data, isAuthenticated: true })
      } else {
        return setState({ user: null, isAuthenticated: false })
      }
    }
    fetchUser()
  }, [])

  const onInputChange = (e) => {
    e.persist()
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onArticleChange = (e) => {
    e.persist()
    setArticle((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onSignIn = async () => {
    const res = await api.post('/signin', form)
    if (res.status === 200) {
      localStorage.setItem('token', res.data.token)
      window.location.reload()
    }
  }

  const onArticleCreate = async () => {
    const res = await api.post('/articles/create', article)
    if (res.status === 201) {
      window.location.reload()
    }
  }

  if (!state.isAuthenticated && !state.user)
    return (
      <div className="d-flex justify-content-center p-3">
        <div style={{ width: '70%' }}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email address</label>
            <input
              type="email"
              value={form.email}
              name="email"
              className="form-control"
              onChange={onInputChange}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Κωδικός</label>
            <input
              type="password"
              value={form.password}
              name="password"
              className="form-control"
              onChange={onInputChange}
              id="exampleInputPassword1"
            />
          </div>

          <button onClick={onSignIn} className="btn btn-danger">
            Είσοδος
          </button>
        </div>
      </div>
    )

  return (
    <div className="d-flex justify-content-center p-3">
      <div style={{ width: '70%' }}>
        <div className="form-group">
          <label htmlFor="exampleInputTitle1">Τίτλος</label>
          <input
            type="text"
            value={article.title}
            name="title"
            className="form-control"
            onChange={onArticleChange}
            id="exampleInputTitle1"
          />
        </div>
        <div className="form-group">
          <label htmlFor="exampleInputContent1">Περιεχόμενο</label>
          <textarea
            rows="3"
            type="text"
            value={article.content}
            name="content"
            className="form-control"
            onChange={onArticleChange}
            id="exampleInputContent1"
          />
        </div>

        <button onClick={onArticleCreate} className="btn btn-danger">
          Καταχώρηση άρθρου
        </button>
      </div>
    </div>
  )
}

export default Admin
