/* eslint-disable jsx-a11y/anchor-is-valid */
import 'components/pagination/index.css'
import React from 'react'

function Pagination(props) {
  const pageNumbers = []

  for (
    let i = 1;
    i <= Math.ceil(props.totalArticles / props.articlesPerPage);
    i++
  ) {
    pageNumbers.push(i)
  }

  if (pageNumbers.length === 1) return null

  return (
    <div className="d-flex justify-content-center">
      <nav aria-label="blog">
        <ul className="pagination">
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`page-item ${
                props.currentPage === number && 'active'
              }`}
            >
              <a
                onClick={() => props.paginate(number)}
                href="#"
                className="page-link"
              >
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default Pagination
