import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'pages/home/index.css'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Element, scroller } from 'react-scroll'
import { Carousel } from 'primereact/carousel'
import { Card } from 'primereact/card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStethoscope,
  faDiagnoses,
  faBookMedical,
  faFileMedicalAlt,
  faLaptopMedical,
  faNotesMedical,
  faFileMedical,
  faHandHoldingMedical
} from '@fortawesome/free-solid-svg-icons'

import Bio from 'pages/home/Bio'
import Map from 'pages/home/Map'

function App() {
  const [activeWidth, setActiveWidth] = useState(window.innerWidth)
  const [slides] = useState([
    { name: 'Slide2', src: `${process.env.PUBLIC_URL}/images/2.jpg` },
    { name: 'Slide3', src: `${process.env.PUBLIC_URL}/images/3.jpg` },
    { name: 'Slide4', src: `${process.env.PUBLIC_URL}/images/4.jpg` },
    { name: 'Slide5', src: `${process.env.PUBLIC_URL}/images/5.jpg` },
    { name: 'Slide6', src: `${process.env.PUBLIC_URL}/images/6.jpg` },
    { name: 'Slide7', src: `${process.env.PUBLIC_URL}/images/7.jpg` },
    { name: 'Slide8', src: `${process.env.PUBLIC_URL}/images/8.jpg` }
  ])
  const location = useLocation()

  useEffect(() => {
    const activeSection = location.pathname.replace('/', '')

    scroller.scrollTo(activeSection, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: activeWidth > 767 ? -60 : -82
    })
  }, [location, activeWidth])

  useEffect(() => {
    const resizeEvent = () => setActiveWidth(window.innerWidth)
    resizeEvent()
    window.addEventListener('resize', resizeEvent)
    return () => window.removeEventListener('resize', resizeEvent)
  }, [])

  function slideTemplate({ src, name }) {
    return (
      <div key={name} className="product-item">
        <div className="product-item-content">
          <div className="p-mb-3 mt-3 product-item-image">
            <img
              src={src}
              alt={name}
              className="product-image carousel-image"
            />
          </div>
        </div>
      </div>
    )
  }

  function renderCardHeader(icon) {
    return (
      <div className="service-card-header">
        <FontAwesomeIcon icon={icon} />
      </div>
    )
  }

  return (
    <main>
      <Element className="section" id="home">
        <div className="card">
          <Carousel
            value={slides}
            numVisible={1}
            numScroll={1}
            autoplayInterval={3000}
            itemTemplate={slideTemplate}
          />
        </div>
      </Element>

      <Element className="section" id="bio">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <Bio />
            </div>
          </div>
        </div>
      </Element>

      <Element className="section" id="services">
        <div className="container-fluid mx-auto">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 service">
              <Card header={renderCardHeader(faStethoscope)}>
                Παρακολούθηση χρονίων και οξέων παθολογικών νοσημάτων
              </Card>
            </div>
            <div className="col-lg-6 col-md-6 col-12 service">
              <Card header={renderCardHeader(faDiagnoses)}>
                Διερεύνηση και αντιμετώπιση Αρτηριακής υπέρταση -
                Δυσλιπιδαιμιών-λοιμώξεις
              </Card>
            </div>
            <div className="col-lg-6 col-md-6 col-12 service">
              <Card header={renderCardHeader(faBookMedical)}>
                Εξειδίκευση Σακχαρώδη Διαβήτη
              </Card>
            </div>
            <div className="col-lg-6 col-md-6 col-12 service">
              <Card header={renderCardHeader(faFileMedicalAlt)}>
                Τοποθέτηση 24 ωρης καταγραφής Αρτηριακής πίεσης
              </Card>
            </div>
            <div className="col-lg-6 col-md-6 col-12 service">
              <Card header={renderCardHeader(faLaptopMedical)}>
                Ηλεκτροκαρδιογραφικός έλεγχος
              </Card>
            </div>
            <div className="col-lg-6 col-md-6 col-12 service">
              <Card header={renderCardHeader(faNotesMedical)}>
                Μέτρηση σφυροβραχιόνιου δείκτη, παλμική οξυμετρία
              </Card>
            </div>
            <div className="col-lg-6 col-md-6 col-12 service">
              <Card header={renderCardHeader(faFileMedical)}>
                Παρακολούθηση Σακχαρωδιαβητικών ασθενών(εκπαίδευση
                ινσουλινοθεραπείας- φαρμακευτική αντιμετώπιση Σακχαρώδους
                διαβήτη)
              </Card>
            </div>
            <div className="col-lg-6 col-md-6 col-12 service">
              <Card header={renderCardHeader(faHandHoldingMedical)}>
                Φαρμακευτική αντιμετώπιση παχυσαρκίας
              </Card>
            </div>
          </div>
        </div>
      </Element>

      <Element className="section contact" id="contact">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-big">
              <div className="contact-form">
                <div className="form-group">
                  <label>Όνομα</label>
                  <input
                    disabled
                    type="text"
                    placeholder="Σταύρος Τσιαγκλής"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Διεύθυνση ιατρείου</label>
                  <input
                    disabled
                    placeholder="Ζαννή 78, Πειραιάς Τ.Κ. 185 37"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Τηλέφωνο Επικοινωνίας</label>
                  <input
                    disabled
                    placeholder="213 0145667"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <a href="mailto:stsiaglis@yahoo.com" className="form-control">
                    stsiaglis@yahoo.com
                  </a>
                </div>
                <div className="form-group">
                  <label>Κινητό τηλέφωνο</label>
                  <a href="tel:6937656571" className="form-control">
                    693 7656571
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-6 col-12">
              <Map />
            </div>
          </div>
        </div>
      </Element>
    </main>
  )
}

export default App
